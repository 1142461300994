import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import VueHighlightJS from "vue-highlightjs"
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)

Vue.config.productionTip = false

Vue.use(VueHighlightJS)

new Vue({
    router,
    render: h => h(App),
    data: {
        message: "Get Twitter user's timeline with VueJS and Axios",
        tweets: null,
        showMobileMenu: false
    },
    methods: {
        myFilter: function () {
            this.isActive = !this.isActive;
            // some code to filter users
        }
    }
}).$mount("#app")
