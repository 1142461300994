<template>
    <div>
        <section class="section">
            <div class="columns">
                <div class="column is-2" id="title-column">
                    <Logo />
                </div>
                <div class="column is-2" id="title-column-phone">
                    <Logo />
                    <br />
                    <br />
                    <h1 class=""><Typemark size="1.5em" /></h1>
                    <h2 class="subtitle">Workshop on Spacecraft Flight Software</h2>
                </div>
                <div class="column">
                    <div>
                        <h1 class="" id="title-column"><Typemark size="3.5em" /></h1>
                        <h2 class="subtitle" id="title-column">Workshop on Spacecraft Flight Software</h2>
                        <p class="">
                            With the advent of faster processors and advanced hardware architectures, the modern spacecraft is highly reliant upon flight
                            software for mission success. Software is integral to most of the spacecraft subsystems ranging from power to propulsion to
                            instrument operations. Additionally, spacecraft developers are moving beyond providing only infrastructure to creating applications
                            that can revolutionize how these vehicles are operated and how data is processed on-board. The Johns Hopkins University Applied
                            Physics Laboratory, The Aerospace Corporation, the NASA Jet Propulsion Laboratory, and the Southwest Research Institute, are the
                            Founding Sponsors of the Flight Software Workshop which was first held in November 2007 at The Johns Hopkins University Applied
                            Physics Laboratory.
                        </p>

                        <br />
                        <div class="columns" id="title-column">
                            <div class="column">
                                <div class="field has-addons">
                                    <p class="control">
                                        <router-link :to="`workshop/${workshop.id}`" class="button is-large is-info" v-if="workshop.latest">
                                            <span v-if="workshop.latest">{{ workshop.name }}</span>
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                            <div class="column is-6">
                                <div class="field has-addons">
                                    <p class="control">
                                        <a href="https://www.youtube.com/channel/UCNUuEywaLBmwgOLRXhFPLPw" class="button is-large is-danger">
                                            <span class="icon is-small">
                                                <i class="fab fa-youtube"></i>
                                            </span>
                                            <span>YouTube</span>
                                        </a>
                                    </p>
                                    <p class="control">
                                        <a href="https://twitter.com/FSWWorkshop" class="button is-large is-info is-dark">
                                            <span class="icon is-small">
                                                <i class="fab fa-twitter"></i>
                                            </span>
                                            <span>Twitter</span>
                                        </a>
                                    </p>
                                    <p class="control">
                                        <a href="https://bsky.app/profile/flightsoftware.org" class="button is-large is-info is-light">
                                            <span class="icon is-small">
                                                <i class="fab fa-bluesky"></i>
                                            </span>
                                            <span>Bluesky</span>
                                        </a>
                                    </p>
                                    <p class="control">
                                        <a href="https://www.linkedin.com/groups/1072247/" class="button is-large is-link">
                                            <span class="icon is-small">
                                                <i class="fab fa-linkedin"></i>
                                            </span>
                                            <span>LinkedIn</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="columns" id="title-column-phone">
                            <div align="center">
                                <div class="column">
                                    <div class="field has-addons">
                                        <p class="control">
                                            <router-link :to="`workshop/${workshop.id}`" class="button is-medium is-info" v-if="workshop.latest">
                                                <span v-if="workshop.latest">{{ workshop.name }}</span>
                                            </router-link>
                                        </p>
                                        <p class="control">
                                            <a href="https://www.youtube.com/channel/UCNUuEywaLBmwgOLRXhFPLPw" class="button is-medium is-danger">
                                                <span class="icon is-small">
                                                    <i class="fab fa-youtube"></i>
                                                </span>
                                            </a>
                                        </p>

                                        <p class="control">
                                            <a href="https://twitter.com/FSWWorkshop" class="button is-medium is-info is-light">
                                                <span class="icon is-small">
                                                    <i class="fab fa-twitter"></i>
                                                </span>
                                            </a>
                                        </p>
                                        <p class="control">
                                            <a href="http://www.linkedin.com/groups?gid=1072247" class="button is-medium is-link">
                                                <span class="icon is-small">
                                                    <i class="fab fa-linkedin"></i>
                                                </span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="container">
                <div align="center">
                    <div class="columns is-multiline">
                        <div class="column is-3" v-for="(curr_sponsor, i) in curr_sponsors" :key="i">
                            <a :href="curr_sponsor.link">
                                <img :src="curr_sponsor.hero" :alt="curr_sponsor.name" :height="curr_sponsor.height" :width="curr_sponsor.width" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section styled-causeway"
            :style="{
                backgroundImage: 'url(' + require('@/assets/spacecraft.jpg') + ')',
            }"
            style="backgroundattachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover"
        >
            <div class="columns">
                <div class="column is-5">
                    <div class="card" style="width: 80%">
                        <div class="card-content">
                            <bsky-embed username="flightsoftware.org" mode="" limit="3" link-target="_blank" link-image="true" load-more="true"> </bsky-embed>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Typemark from "@/components/Typemark.vue";
import sponsors from "@/sponsors.js";
import _ from "lodash";
import Logo from "@/components/Logo.vue";
import showdown from "showdown";
import events from "@/events.js";
export default {
    name: "Home",
    props: {
        workshop: {
            type: Object,
        },
    },
    components: {
        Typemark,
        Logo,
    },
    data() {
        let converter = new showdown.Converter({
            simplifiedAutoLink: true,
        });
        return {
            events: events.map((event) => {
                return {
                    ...event,
                    contents: converter.makeHtml(event.contents.join("\n\n")),
                };
            }),
            curr_sponsors: _.sortBy(_.filter(sponsors, "current")),
        };
    },
    mounted() {
        const script = document.createElement("script");
        script.type = "module";
        script.src = "https://cdn.jsdelivr.net/npm/bsky-embed/dist/bsky-embed.es.js";
        script.async = true;
        document.head.appendChild(script);
    },
};
window.twttr = (function (d, s, id) {
    var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
        t._e.push(f);
    };

    return t;
})(document, "script", "twitter-wjs");
</script>

<style lang="scss" scoped>
.styled-causeway {
    min-height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    // backgroundPosition: `0 ${-20 + this.state.scrollOffset / 20}px`,
}
@media only screen and (max-width: 600px) {
    #title-column {
        display: none;
    }
}
@media only screen and (min-width: 600px) {
    #title-column-phone {
        display: none;
    }
}
</style>
