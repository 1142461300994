const sponsors = {
    adacore: {
        name: "adacore",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/adacore.jpg"),
        link: "https://www.adacore.com/",
        height: "130px",
        width: "130px"
    },
    aerospace: {
        name: "aerospace",
        founder: true,
        public: false,
        hero: require("@/assets/sponsors/aerospace.jpg"),
        link: "https://aerospace.org/",
    },
    agi: {
        name: "agi",
        founder: false,
        public: false,
        hero: require("@/assets/sponsors/agi.png"),
        link: "https://www.agi.com/home",
    },
    caltech_ctme: {
        name: "caltech_ctme",
        founder: false,
        public: true,
        current: true,
        hero: require("@/assets/sponsors/caltech-ctme.png"),
        link: "https://ctme.caltech.edu/",
        height: "100%",
    },
    concurrent: {
        name: "concurrent",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/concurrent-real-time2.png"),
        link: "http://www.concurrent-rt.com/",
    },
    ddci: {
        name: "ddci",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/DDC-ILogoHighRes.jpg"),
        link: "https://www.ddci.com/",
        height: "130px",
        width: "130px"
    },
    frontgrade_gaisler: {
        name: "frontgrade_gaisler",
        founder: false,
        public: false,
        current: true,
        hero: require("@/assets/sponsors/frontgrade-gaisler.png"),
        link: "https://www.caes.com/gaisler",
        height: "100%",
        width: "100%"
    },
    gaisler: {
        name: "gaisler",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/gaisler.png"),
        link: "https://www.gaisler.com/",
        height: "200px",
        width: "200px"
    },
    grammatech: {
        name: "grammatech",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/grammatech.jpg"),
        link: "https://www.grammatech.com/",
    },
    jhuapl: {
        name: "jhuapl",
        founder: true,
        public: false,
        hero: require("@/assets/sponsors/apl.png"),
        link: "https://www.jhuapl.edu/",
    },
    jpl: {
        name: "jpl",
        founder: true,
        public: false,
        hero: require("@/assets/sponsors/jpl.png"),
        link: "https://www.jpl.nasa.gov/",
    },
    klockwork: {
        name: "klockwork",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/klockwork.jpg"),
        link: "https://www.perforce.com/products/klocwork",
    },
    ldra: {
        name: "ldra",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/ldra.png"),
        link: "https://ldra.com/",
        height: "100%",
    },
    mathworks: {
        name: "mathworks",
        founder: false,
        public: true,
        current: true,
        hero: require("@/assets/sponsors/mathworks.png"),
        link: "https://www.mathworks.com/",
        height: "200px",
        width: "200px"
    },
    metecs: {
        name: "metecs",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/Metecs_Logo_Large.png"),
        link: "https://metecs.com/",
        height: "130px",
        width: "130px"
    },
    oar: {
        name: "oar",
        founder: false,
        public: true,
        current: true,
        hero: require("@/assets/sponsors/oar.png"),
        link: "http://www.rtems.com/",
        height: "110px",
        width: "110px"
    },
    odyssey: {
        name: "odyssey",
        founder: false,
        public: true,
        current: true,
        hero: require("@/assets/sponsors/odyssey.png"),
        link: "https://odysseysr.com/",
    },
    star_dundee: {
        name: "star_dundee",
        founder: false,
        public: true,
        current: true,
        height: "150px",
        width: "200px",
        hero: require("@/assets/sponsors/star-dundee.png"),
        link: "http://www.star-dundee.com/",
    },
    swri: {
        name: "swri",
        founder: true,
        public: false,
        hero: require("@/assets/sponsors/swri.jpg"),
        link: "https://www.swri.org/",
    },
    tttech: {
        name: "tttech",
        founder: false,
        public: true,
        current: false,
        hero: require("@/assets/sponsors/tttech.jpg"),
        link: "https://www.tttech.com/",
    },
}
export default sponsors;
